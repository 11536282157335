import { Layout, Typography, Col } from 'antd';
import ReactDOM from 'react-dom';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

function template(content) {
    return ReactDOM.render(
  <>
    <Col xs={2} sm={4} md={6} lg={8} xl={10}>
    <Layout>
        <Header><Title>Checkthese</Title></Header>
        <Content><content/></Content>
        <Footer>
          <div align="center">
              Made with ❤ by <a href="https://github.com/ceorleorn">Ceorleorn</a>
            </div>
        </Footer>
    </Layout>
    </Col>
    
  </>
);
}

export default template;